<template>
  <section>
    <div class="content has-text-centered">
      <h1 class="title is-4">Support</h1>
      <img class="divider" src="../assets/spacer.png" />
    </div>
    <b-collapse animation="slide" v-for="(collapse, index) of collapses" :key="index" :open="isOpen == index"
      @open="isOpen = index">
      <template #trigger="props">
        <div class="card-header" role="button">
          <p class="card-header-title">
            {{ collapse.title }}
          </p>
          <a class="card-header-icon" v-show="props.open">
            <b-icon icon="minus"></b-icon>
          </a>
          <a class="card-header-icon" v-show="!props.open">
            <b-icon icon="plus"></b-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <div>
          <span v-html="collapse.text"> {{ collapse.text }}</span>
        </div>
      </div>
    </b-collapse>
    <div class="content has-text-centered">
      <h1 class="title is-6">Still have questions?</h1>
      <div class="is-flex is-justify-content-center">
        <img src="../assets/message.svg" class="icon" /><span>Email us at
          <a class='link' href="mailto:support@guiders.pt"> support@guiders.pt</a>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  head() {
    return {
      title: 'Guiders.pt | Frequently Asked Questions',
      link: [
        { rel: 'canonical', href: 'https://guiders.pt/frequently-asked-questions' }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Get answers to your questions on Guiders with our comprehensive FAQ. Find solutions to common inquiries, tips, and expert insights to make your next experience easier for you.'
        },
        {
          hid: 'link',
          name: 'link',
          content: 'https://guiders.pt/frequently-asked-questions'
        }
      ]
    }
  },
  data() {
    return {
      isOpen: 0,
      collapses: [
        {
          title: "Booking confirmation",
          text: `After paying for your booking, you'll receive an email, to the email you provided, with your booking details. If you didn't receive an email please contact us at <a class='link' href='mailto:support@guiders.pt'>support@guiders.pt</a>`,
        },
        {
          title: "I'm running late to my activity. What do I do?",
          text: "If you are running late to your activity please let us know by emailing us at <a class='link' href='mailto:support@guiders.pt'>support@guiders.pt</a>.",
        },
        {
          title: "I want to cancel my activity",
          text: "If you want to cancel your activity email us at <a class='link' href='mailto:support@guiders.pt'>support@guiders.pt</a>.",
        },
        {
          title: "How can I join the affiliates program?",
          text:
            "To join our affiliates program go to <a href='affiliates' class='link'> this<a> page.",
        },
        {
          title: "Cancellations and refunds",
          text: `If you cancel your booking within the cancellation policy, your refund will be processed via the same payment method you used to book the activity.

  If your booking doesn't go as planned or you are unhappy with your experience and would like to request a refund, please send us an email to <a class='link' 
  href='mailto:support@guiders.pt'>support@guiders.pt</a> telling us the reason(s) why you'd like a refund. Refund requests are evaluated on a case-by-case basis.

In the unlikely event that we need to cancel your booking, you are always entitled to a full refund. If you've received a cancellation confirmation email from us, your refund will be processed via the same payment method you used to book the activity`,
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.icon {
  margin-right: 8px;
}

.card-header {
  box-shadow: none;
  border-radius: 8px;
  margin-bottom: 11px;
}

.card-header-title {
  color: #0e3d4d;
}

.card-content {
  padding: 14px;
}

::v-deep {
  .a {
    color: #fd5b2f !important;
  }
}

.title {
  margin-bottom: 0.7rem;
}

.divider {
  margin-bottom: 10px;
  width: 58px;
}

.content {
  margin-top: 2rem;
}

.title {
  color: #0e3d4d;
}
</style>
